<!--
 * @Author: cuixuecheng
 * @Date: 2023-10-25 11:09:30
 * @LastEditors: cuixuecheng
 * @LastEditTime: 2023-12-13 14:34:40
 * @Description: 
-->
<template>
  <div id="login">
    <template>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        status-icon
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="账户" prop="account">
          <el-input
            v-model="ruleForm.account"
          />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="ruleForm.password" type="password" autocomplete="off" />
        </el-form-item>
        
        <el-form-item>
          <el-button type="primary" :disabled="loginStatus"  @click="submitForm()"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </template>
  </div>
</template>

<script>
import SPhone from 'sphone-sdk'
import { Message } from 'element-ui'

export default {
  name: 'App',
  props: {
    loginSuccess: Boolean
  },
  data() {
    return {
      ruleForm:{
        account: '',
        agentNumber: '',
        password:''
      },
      loginStatus: false
    }
  },
  watch: {
    loginSuccess(val) {
      this.loginStatus = val
    }
  },
  methods: {
    submitForm() {
      const account = this.ruleForm.account
      
      const agentNumber = account.split('@')[0]
      const accountId = account.split('@')[1]
      const password = this.ruleForm.password
      this.loginType = 'WEBRTC'
      this.agentStatusNumber = '0'
      window.SPhone = new SPhone({
        server: 'https://kf.dev1.xinmuai.net/api',
        accountId: accountId,
        agentNumber: agentNumber,
        password: password,
        error: (error) => {
          console.log(error)
          if (error) {
            Message({
              message: error.message,
              type: 'error',
              duration: 5 * 1000
            })
          }
        },
        success: (res) => {
          console.log(res)
          this.$emit('handleLogin', res)
        }
      })
    }
  },
}
</script>

<style>
#login {
  width: 500px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
