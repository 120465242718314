<!--
 * @Author: cuixuecheng
 * @Date: 2023-10-25 10:24:39
 * @LastEditors: cuixuecheng
 * @LastEditTime: 2023-12-13 14:33:03
 * @Description: 
-->
<template>
  <div id="app">
    <div style="display: block;">
      <Login  @handleLogin="handleLogin" :loginSuccess="loginSuccess"/>
      <PhoneBar :loginSuccess="loginSuccess" :loginInfo="loginInfo" @handleLogin="handleLogin" />
    </div>
  </div>
</template>

<script>

import Login from './Login.vue'
import PhoneBar from './components/phoneBar.vue'

export default {
  name: 'App',
  components:{Login,PhoneBar},
  data(){
    return {
      loginSuccess: false,
      loginInfo: {}
    }
  },
  methods:{
    handleLogin(res) {
      console.log(res)
      if (res) {
        this.loginSuccess = true
        this.loginInfo = res.data
      } else {
        this.loginSuccess = false
      } 
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
